import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  state: {
    loggedInUser:
      localStorage.getItem('userInfo') != null
        ? JSON.parse(localStorage.getItem('userInfo'))
        : null,
    loading: false,
    error: null,
    userProfile: {
      b_LoggedIn: false,
      skills: null,
      a_SeminarCenter: [],
      firstname: null,
      lastname: null,
      o_ConfigPortal: {
        b_ActiveStudentDashboard: true
      },
      b_SiteManager: false,
    },
    isLoaded: null,
    o_CurrentPlanningPeriod: {
      planningPeriodID: null,
      description: null,
      startDate: null,
      endDate: null
    },
    o_CurrentRole: {
      i_AddressRoleID: null,
      s_Role: null
    },
    a_PlanningPeriods: [],
    o_Impersonation: {
      b_Status: false,
      i_AddressRoleID: null,
      s_FullName: null,
      s_Role: null,
      a_PlanningPeriods: [],
    }
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    loading: state => state.loading,
    error: state => state.error,
    getProfile: state => state.userProfile,
    getRoles: state => state.loggedInUser.roles,
    getRoleNames: state => {
      const La_Roles = []
      state.loggedInUser.roles.forEach(role => {
        La_Roles.push(role.s_Role)
      })
      return La_Roles
    },
    getPlanningPeriodID: state => state.o_CurrentPlanningPeriod.planningPeriodID,
    getPlanningPeriod: state => state.o_CurrentPlanningPeriod,
    getPlanningPeriods: state => state.a_PlanningPeriods,
    getCurrentRole: state => state.o_CurrentRole,
    getUserID: state => state.loggedInUser.uid,
    getSeminarCenter: state => state.userProfile.a_SeminarCenter,
    getConfigPortal: state => state.userProfile.o_ConfigPortal,
    isLoggedIn: state => state.userProfile.b_LoggedIn,
    isSiteManager: state => state.userProfile.b_SiteManager,
    getImpersonation: state => state.o_Impersonation,
  },
  mutations: {
    setUser (state, data) {
      state.loggedInUser = data
      state.loading = false
      state.error = null
      if (data.uid !== null && data.uid > 0) state.userProfile.b_LoggedIn = true
    },
    setLogout (state) {
      state.loggedInUser = null
      state.loading = false
      state.error = null
      // this.$router.go("/");
    },
    setLoading (state, data) {
      state.loading = data
      state.error = null
    },
    setError (state, data) {
      state.error = data
      state.loggedInUser = null
      state.loading = false
    },
    clearError (state) {
      state.error = null
    },
    setRoles (state, data) {
      state.loggedInUser.roles = data
    },
    setPlanningPeriods (state, data) {
      state.a_PlanningPeriods = data
    },
    setCurrentRole (state, data) {
      state.o_CurrentRole = data
    },
    setProfile (state, data) {
      state.userProfile.skills = data.a_Skills
      state.userProfile.a_SeminarCenter = data.a_SeminarCenter
      state.userProfile.firstname = data.s_Firstname
      state.userProfile.lastname = data.s_Lastname
      state.userProfile.b_SiteManager = data.b_SiteManager
    },
    setCurrentPlanningPeriod (state, data) {
      state.o_CurrentPlanningPeriod = data
    },
    setImpersonation (state, data) {
      state.o_Impersonation = data
    },
  },
  actions: {
    login ({ commit }, Ai_UserID) {
      commit('clearError')
      commit('setLoading', true)
      const newUser = {
        uid: Ai_UserID,
        roles: [],
        firstname: null,
        lastname: null,
      }
      localStorage.setItem('userInfo', JSON.stringify(newUser))
      commit('setUser',
        {
          uid: Ai_UserID,
          roles: [], // admin, parent, student, teacher, company
        },
      )
    },
    setRoles ({ commit }, Aa_Roles) {
      const o_UserInfo = JSON.parse(localStorage.getItem('userInfo'))
      const updatedUser = {
        uid: o_UserInfo.uid,
        roles: Aa_Roles,
      }
      localStorage.setItem('userInfo', JSON.stringify(updatedUser))
      commit('setRoles', Aa_Roles)
    },
    setPlanningPeriods ({ commit }, Aa_PlanningPeriods) {
      commit('setPlanningPeriods', Aa_PlanningPeriods)
    },
    setCurrentRole ({ commit }, Ao_CurrentRole) {
      commit('setCurrentRole', Ao_CurrentRole)
    },
    setProfile ({ commit }, Ao_Profile) {
      commit('setProfile', Ao_Profile)
    },
    signUserUp ({ commit }, data) {
      commit('setLoading', true)
      commit('clearError')
      firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.password)
        .then(user => {
          commit('setLoading', false)

          const newUser = {
            uid: user.user.uid,
          }
          localStorage.setItem('userInfo', JSON.stringify(newUser))
          commit('setUser', newUser)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          localStorage.removeItem('userInfo')
        })
    },
    signOut ({ commit }) {
      localStorage.removeItem('userInfo')
      commit('setLogout')
    },
    setCurrentPlanningPeriod({ commit }, data) {
      commit('setCurrentPlanningPeriod', data)
    },
    setImpersonation ({ commit }, data) {
      commit('setImpersonation', data)
    }
  },
}
