export default function grant (context) {
    const roles = context.to.meta.hasOwnProperty('roles') ? context.to.meta.roles : []
    const errorRedirection = () => {
        try {
            throw { statusCode: 401, message: 'does not have access'}
        } catch (error) {
            return context.next(`/${context.to.params.locale}/app/sessions/error`)
        }
    }
    if (roles.length < 1) {
        errorRedirection()
    }
    if (!roles.some(role => context.store.getters.getRoleNames.includes(role))) {
        errorRedirection()
    }
    return context.next()
}