<template>
  <v-list-group
    :value="uniqueItem"
    :prepend-icon="item.icon"
    :sub-group="subGroup"
    no-action
    flat
    :active-class="
      ` ${
        isDark && getThemeMode.verticalSidebarDrawerColor != 'white'
          ? getThemeMode.verticalSidebarDrawerColor + ' darken-1 white--text'
          : 'grey lighten-4'
      }`
    "
    class="pl-0"
    @click="updateCurrentRole(item.role)"
  >
    <!-- :group="group" -->
    <!-- text-gray-900 bg-gray-200 -->
    <template v-slot:activator>
      <v-list-item-icon
        v-if="text"
        class="v-list-item__icon--text"
        v-text="computedText"
      />

      <v-list-item-content>
        <v-list-item-title
          class="text-14"
          v-text="item.title"
        />
      </v-list-item-content>
    </template>

    <template v-for="(child, i) in children">
      <base-item-sub-group
        v-if="child.children"
        :key="`sub-group-${i}`"
        :item="child"
        class="pl-0"
      />

      <base-item
        v-else
        :key="`item-${i}`"
        class=""
        :item="child"
        :text="false"
      />
    </template>
  </v-list-group>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  // Utilities
  import kebabCase from 'lodash/kebabCase'
  import Themeable from 'vuetify/lib/mixins/themeable'
  import { isSuperAdmin } from '../../worker/worker-api'
  export default {
    name: 'ItemGroup',
    mixins: [Themeable],

    inheritAttrs: false,
    props: {
      uniqueItem: false,
      item: {
        type: Object,
        default: () => ({
          avatar: undefined,
          group: undefined,
          title: undefined,
          subHeader: undefined,
          children: [],
        }),
      },
      subGroup: {
        type: Boolean,
        default: false,
      },
      text: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        listModel: 0,
        b_IsSuperAdmin: false,
      }
    },

    computed: {
      ...mapGetters(['getThemeMode', 'getPlanningPeriods']),
      children () {
        let s_BasePath = this.$i18n.locale + '/app';
        if (this.b_IsSuperAdmin) {
          s_BasePath = this.$i18n.locale + '/admin-ms';
        }
        return this.item.children.map(item => ({
          ...item,
          to: !item.to ? undefined : `/${s_BasePath}/${this.item.group}/${item.to}`,
        }))
      },
      computedText () {
        if (!this.item || !this.item.title) return ''

        let text = ''

        this.item.title.split(' ').forEach(val => {
          text += val.substring(0, 1)
        })

        return text
      },
      group () {
        return this.genGroup(this.item.children)
      },
    },
    async beforeCreate () {
      this.b_IsSuperAdmin = await isSuperAdmin()
    },

    methods: {
      genGroup (children) {
        return children
          .filter(item => item.to)
          .map(item => {
            const parent = item.group || this.item.group
            let group = `${parent}/${kebabCase(item.to)}`

            if (item.children) {
              group = `${group}|${this.genGroup(item.children)}`
            }

            return group
          })
          .join('|')
      },
      updateCurrentRole(As_Role) {
        if(As_Role === undefined) return;
        this.setCurrentRole({
          i_AddressRoleID: this.$smt.getAddressRoleID(As_Role),
          s_Role: As_Role
        });
      },
      ...mapActions(['setCurrentRole', 'setCurrentPlanningPeriod'])
    },
  }
</script>

<style></style>
