<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
    metaInfo () {
      return {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Minerva',
        // all titles will be injected into this template
        titleTemplate:
          '%s | Minerva ',
      }
    },
    async beforeCreate () {
    },
    async created () {
      const mq = window.matchMedia('(prefers-color-scheme: dark)')
      mq.addEventListener('change', e => {
        this.$vuetify.theme.dark = e.matches
      })
      this.getRouterParams()
    },
    beforeMount () {

    },
    methods: {
      getRouterParams () {
      },
    },
  }
</script>
