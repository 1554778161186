var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"mr-auto",attrs:{"cols":"auto"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.title))])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [(_vm.marks)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","small":"","dark":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-certificate")])],1)]}}],null,true)},[_c('span',[_vm._v("Noten")])]):_vm._e()]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{attrs:{"to":{
              name: 'experience-marks-pma'
            },"disabled":_vm.validateCurrent('experience-marks')}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-certificate")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Erfahrungsnoten")])],1)],1),_c('v-list-item',{attrs:{"to":{
              name: 'quarter-marks-pma'
            },"disabled":_vm.validateCurrent('quarter-marks')}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-certificate")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Zwischenbericht")])],1)],1),_c('v-list-item',{attrs:{"to":{
              name: 'final-marks-pma'
            },"disabled":_vm.validateCurrent('final-marks')}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-certificate")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Abschlussnoten")])],1)],1)],1)],1)],1),(_vm.comments)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"success","fab":"","small":"","dark":"","href":_vm.getLink('comments')}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-comment")])],1)]}}],null,false,3523290613)},[_c('span',[_vm._v("Kommentare")])]):_vm._e(),(_vm.presence)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mr-3",attrs:{"color":"error","fab":"","small":"","dark":"","href":_vm.getLink('presence')}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-alarm")])],1)]}}],null,false,4042573206)},[_c('span',[_vm._v("Präsenzkontrolle")])]):_vm._e(),(_vm.edit_marks)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mr-2",attrs:{"color":"orange","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.$emit('edit-mode', true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,false,3701095370)},[_c('span',[_vm._v("Bearbeiten")])]):_vm._e(),(_vm.new_mark)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"teal","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.$emit('new-mark', true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-shield-plus")])],1)]}}],null,false,687245946)},[_c('span',[_vm._v("Neue Note")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }